<template>
  <div>
    <b-card-text>
      Ürünler her saat başı paraşüt deki ürünler ile güncellenmektedir. Aşağıdaki butona basarak hemen güncelleyebilirsiniz.
    </b-card-text>
    <h2>👇</h2>
    <b-button
      variant="primary"
      :disabled="loading"
      class="mr-1"
      @click="senkronProduct"
    >
      <b-spinner
        v-if="loading"
        small
      />
      Ürünleri yenile
    </b-button>

  </div>
</template>
<script>
import { BButton, BSpinner, BCardText } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCardText,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async senkronProduct() {
      this.loading = true
      await this.$http.patch('/product').then(res => {
        if (res.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Başarılı',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Bir hata ile karşılaştık. 😥',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      })
      this.loading = false
    },
  },
}
</script>
